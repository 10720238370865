//imports
import { Vector2, WebGLRenderer, PCFSoftShadowMap, SRGBColorSpace, ReinhardToneMapping } from 'three';

// Viewport dimensions
const viewport = {
    width: window.innerWidth,
    height: window.innerHeight
}

// mobile setup
let mobile = 'ontouchstart' in window || navigator.maxTouchPoints

if (mobile) {
    let landscape = window.matchMedia("(orientation: landscape)").matches;
    landscape ? $('.mobile-landscape-modal').addClass('active') : $('.mobile-landscape-modal').removeClass('active')
    window.matchMedia("(orientation: landscape)").addEventListener("change", e => {
        landscape = e.matches;
        landscape ? $('.mobile-landscape-modal').addClass('active') : $('.mobile-landscape-modal').removeClass('active')
    });
}

// Mousemove position tracking
let pointer = new Vector2()
function onPointerMove(event) {
    pointer.x = (event.clientX / viewport.width) * 2 - 1;
    pointer.y = - (event.clientY / viewport.height) * 2 + 1;
    pointer.xNorm = event.clientX;
    pointer.yNorm = event.clientY;
}

// autoresize
function autoresize(camera, renderer) {
    window.addEventListener('resize', () => {
        viewport.width = window.innerWidth
        viewport.height = window.innerHeight
        camera.aspect = viewport.width / viewport.height
        camera.updateProjectionMatrix()
        renderer.setSize(viewport.width, viewport.height)
    })
}



export { mobile, onPointerMove, pointer, viewport, autoresize }