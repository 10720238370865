
let standTypes = {
    standalone: {
        name: 'standalone',
        width: 0.6,
        height: 1.86,
        objectZ: -0.115,
        cheps: true,
        branding: 'default'
    },
    column: {
        name: 'column',
        width: 0.6,
        height: 1.86,
        objectZ: -0.1,
        branding: 'top'
    },
    wall: {
        name: 'wall',
        width: 2,
        height: 1.86,
        objectZ: -0.1,
        branding: 'top'
    },
    shelf: {
        name: 'shelf',
        width: 0.6,
        height: 1.86,
        objectZ: -0.1,
        branding: 'top'
    }
}

let activeStandType = standTypes.standalone

export {
    standTypes,
    activeStandType
}