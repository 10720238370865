function round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

function snapToPanel(objPosition, offset) {
    let linesArr = []
    let line = 0.0765
    let lineBottom = 0.25

    for (let i = 0; i <= 20; i++) {
        linesArr.push(round(lineBottom + line * i, 6))
    }

    linesArr.sort((a, b) => {
        return Math.abs(objPosition - a) - Math.abs(objPosition - b);
    })

    return linesArr[0];
}

function clamp(number, min, max) {
    return Math.max(min, Math.min(number, max));
}



function updateModelLimits(object) {
    object.userData.update = function () {
        object.position.clamp(object.userData.limit.min, object.userData.limit.max);
    }
}

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function loadInterfaceModel(model, scene, gLoader) {
    if (model === 'button_bin') {
        gLoader.load(
            "images/" + model + ".glb",
            function (gltf) {
                binButtonModel = gltf.scene
                binButtonModel.traverse(function (child) {
                    if (child instanceof THREE.Mesh) {
                        if (child.name === 'buttonwhite') {
                            child.material = materials.whiteShinyMat;
                        }
                        if (child.name === 'bin') {
                            child.material = materials.redShinyMat;
                        }
                        child.castShadow = false;
                        child.receiveShadow = true;
                    }
                });
                scene.add(binButtonModel)
            },
            function (xhr) { },
            function (error) { }
        );
    }
}

function generateRandomHash(length = 10) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}


export {
    round,
    updateModelLimits,
    snapToPanel,
    getRandomInt,
    clamp,
    loadInterfaceModel,
    generateRandomHash
}